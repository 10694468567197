import React from "react"
import PropTypes from "prop-types"
import styles from "./check-box.module.scss"
import { useField, splitFormProps } from "react-form"
import { MdError } from "react-icons/md"
import _ from "lodash"
import classNames from "classnames"
import ErrorMessage from "../error-message/error-message"

const Checkbox = React.forwardRef((props, ref) => {
  // Let's use splitFormProps to get form-specific props
  const [
    field,
    fieldOptions,
    { label, mandatory, checked, onChange, className, errorMessage, isDisabled, ...rest },
  ] = splitFormProps({
    ...props,
    type: "checkbox",
  })

  var opts = {}
  if (fieldOptions) opts = _.extend(opts, fieldOptions)
  // Use the useField hook with a field and field options
  // to access field state
  const {
    value,
    meta: { error, isTouched, isValidating },
    getInputProps,
    setValue,
  } = useField(field, opts)

  if (checked && value !== true) {
    setValue(true)
  }

  const onChangeHandler = event => {
    setValue(event.target.checked)
    if (onChange) {
      // pass change event along if being listened for
      onChange(event)
    }
  }
  // Build the field
  return (
    <div className={classNames(styles.formCheck, className)}>
      <label className={styles.container}>
        {label}
        <input
          disabled={isDisabled}
          id={field}
          checked={value === true && !isDisabled}
          {...getInputProps({ ref, ...rest })}
          onChange={onChangeHandler}
        />
        {mandatory ? <span className={styles.star}> * </span> : ""}
        <span className={styles.checkmark}></span>
      </label>
      <div className={styles.errorWrap}>
        {isValidating ? (
          <em>Validating...</em>
        ) : isTouched && error ? (
          <div
            className={styles.errorIcon}
            data-tip={error}
            data-event="click focus"
          >
            <MdError />
          </div>
        ) : (
          ""
        )}
        {error ? <ErrorMessage errorMessage={error} /> : ""}
      </div>
    </div>
  )
})

Checkbox.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  isToggle: PropTypes.bool,
  mandatory: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
}

export default Checkbox
